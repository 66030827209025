import * as reactRouterDom from 'react-router-dom';
import SuperTokens from 'supertokens-auth-react';
import emailpassword from 'supertokens-auth-react/recipe/emailpassword';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import session from 'supertokens-auth-react/recipe/session';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import { gql } from '@apollo/client';
import { client } from 'apollo';
import { getDefaultStore } from 'jotai';
import { ERoutes } from 'routing';
import { showError } from 'utils';
import { isAuthorizedAtom, noPerms, permsAtom, userIdAtom, type IAuthData } from './authAtoms';

const GET_USER = gql`
  query GetMe {
    me {
      role
      permissions
    }
  }
`;

const store = getDefaultStore();
store.sub(userIdAtom, async () => {
  const userId = store.get(userIdAtom);
  if (userId) {
    try {
      const user = await client.query<{ me: { permissions: string[] } }>({ query: GET_USER });
      const permsArr = user.data.me.permissions;
      store.set(permsAtom, {
        loaded: true,
        reportsAccounting: permsArr.includes('view_report_transactions'),
        reportsPlayerBrowser: permsArr.includes('view_combined_transactions_by_players'),
        clients: permsArr.includes('view_clients'),
        clusters: permsArr.includes('view_clusters'),
        reportsAccountingExport: permsArr.includes('export_report_transactions_csv'),
        // todo
        reports: true,
        reportsGameBrowser: true,
        team: true,
      });
    } catch (err) {
      console.error(err);
      signOut();
      showError(err as Error);
      store.set(permsAtom, noPerms);
    }
  } else {
    store.set(permsAtom, noPerms);
  }
});

export const initSupertokens = () => {
  SuperTokens.init({
    appInfo: {
      appName: 'backoffice-v2',
      apiDomain: import.meta.env.VITE_API_URL || '*',
      websiteDomain: import.meta.env.VITE_APP_URL || '*',
      apiBasePath: '/auth',
      websiteBasePath: ERoutes.SignIn,
    },
    recipeList: [emailpassword.init(), session.init()],
  });
  fetchUser();
};

export const fetchUser = async () => {
  if (await session.doesSessionExist()) {
    store.set(isAuthorizedAtom, true);
    store.set(userIdAtom, await session.getUserId());
  } else {
    store.set(isAuthorizedAtom, false);
    store.set(userIdAtom, null);
  }
};

export const signOut = async () => {
  await emailpassword.signOut();
  await session.signOut();
  await fetchUser();
};

export const signIn = async ({ email, password }: IAuthData) => {
  const response = await emailpassword.signIn({
    formFields: [
      { id: 'email', value: email },
      { id: 'password', value: password },
    ],
  });
  // this is workaround to use react-router-dom navigate for supertokens redirect
  // ToDo: make own redirect inside useAuth hook
  getSuperTokensRoutesForReactRouterDom(reactRouterDom, [EmailPasswordPreBuiltUI]);
  fetchUser();
  return response;
};

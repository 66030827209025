import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const leftMenuOpenedAtom = atomWithStorage('leftMenuOpened', false);

export const useMenu = () => {
  const [leftMenuOpened, setLeftMenuOpened] = useAtom(leftMenuOpenedAtom);

  return { leftMenuOpened, setLeftMenuOpened };
};

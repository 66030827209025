import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Typography } from 'components';
import { usePerms } from 'hooks';

export const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const perms = usePerms();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        width: '100%',
        position: 'absolute',
        left: 0,
        mt: 6,
      }}
    >
      {perms.loaded ? (
        <>
          <Typography variant="h5">{t('notFound.title')}</Typography>
          <Typography variant="body1">{t('notFound.subtitle')}</Typography>
          <Button variant="contained" onClick={() => navigate(-1)}>
            {t('notFound.prev')}
          </Button>
        </>
      ) : (
        <CircularProgress size={80} />
      )}
    </Box>
  );
};

export default NotFound;

import { type PropsWithChildren } from 'react';
import { type SxProps } from '@mui/material';
import { Box, drawerWidth } from 'components';
import { useMenu } from 'hooks';

interface IProps extends PropsWithChildren {
  sx?: SxProps;
}

export const DataGridBox = ({ children, sx }: IProps) => {
  const { leftMenuOpened } = useMenu();
  const width = `calc(100vw - ${64 + (leftMenuOpened ? drawerWidth : 0)}px)`;
  const height = 'calc(100vh - 120px)';
  return (
    <Box
      sx={[
        (theme) => ({
          width,
          height,
          position: 'relative',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
};

export default DataGridBox;

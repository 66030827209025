import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  DataGrid,
  GridCell,
  GridColumnMenu,
  GridOverlay,
  useGridApiRef,
  type GridRenderCellParams,
  type GridSortModel,
} from '@mui/x-data-grid';
import { useSetAtom } from 'jotai';
import { DataGridBox, DataGridPagination, DataGridToolbar, IconButton } from 'components';
import { useLoading, usePerms } from 'hooks';
import { ERoutes } from 'routing';
import { generateColumns } from './playerBrowserGenerateColumns';
import { menuCellAtom, PlayerBrowserTableContextMenu } from './PlayerBrowserTableContextMenu';

const densityMap: Record<string, 'small' | 'medium' | 'large'> = {
  compact: 'small',
  standard: 'medium',
  comfort: 'large',
};

interface IProps {
  page: number;
  setPage: (page: number) => void;
  setSort: (sort: GridSortModel) => void;
  sort: GridSortModel;
  data: object[];
  totalCount: number;
}

export const PlayerBrowserTable = ({ setSort, page, setPage, data, totalCount, sort }: IProps) => {
  const apiRef = useGridApiRef();
  const perms = usePerms();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { loading } = useLoading();
  const setMenuCell = useSetAtom(menuCellAtom);
  const navigateToPlayer = useCallback(
    (id: string, newTab?: boolean) => {
      const queryParams = new URLSearchParams(window.location.search);
      const path = `/${ERoutes.PlayerBrowserDetails.replace(':id', id)}?${queryParams.toString()}`;
      newTab ? window.open(path, '_blank') : navigate(path);
    },
    [navigate],
  );

  const columns = useMemo(
    () => [
      ...generateColumns(t, perms),
      {
        editable: false,
        field: 'action',
        disableColumnMenu: true,
        sortable: false,
        headerName: t('playerBrowser.action'),
        renderCell: (props: GridRenderCellParams) => (
          <IconButton
            size={densityMap[apiRef.current?.state.density] || 'medium'}
            onClick={() => navigateToPlayer(props.row.playerId)}
          >
            <VisibilityIcon fontSize={densityMap[apiRef.current?.state.density] || 'medium'} />
          </IconButton>
        ),
      },
    ],
    [i18n.language, perms],
  );

  return (
    <DataGridBox>
      <DataGrid
        rows={data}
        columns={columns}
        apiRef={apiRef}
        loading={loading}
        disableColumnFilter
        disableRowSelectionOnClick
        isCellEditable={() => false}
        sortingMode="server"
        sortModel={sort}
        editMode="row"
        onSortModelChange={setSort}
        hideFooter={data.length === 0}
        disableColumnSorting={loading}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        }}
        slots={{
          noRowsOverlay: () => <GridOverlay>{t('base.NoData')}</GridOverlay>,
          toolbar: () => <DataGridToolbar localStorageKey="player-browser" />,
          columnMenu: (props) => <GridColumnMenu {...props} slots={{ columnMenuColumnsItem: null }} />,
          pagination: () => <DataGridPagination count={totalCount} page={page} onPageChange={setPage} />,
          cell: (props) => {
            return (
              <GridCell
                {...props}
                onContextMenu={(evt: MouseEvent) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  if (props.column.field === 'action') {
                    return;
                  }
                  setMenuCell({ rowId: props.rowId, column: props.column });
                }}
              />
            );
          },
        }}
      />
      <PlayerBrowserTableContextMenu apiRef={apiRef} navigateToPlayer={navigateToPlayer} />
    </DataGridBox>
  );
};

export default PlayerBrowserTable;

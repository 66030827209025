import { useTranslation } from 'react-i18next';
import { Box, Button, Popover, Typography } from 'components';

interface IProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  close: (confirmed: boolean) => void;
  text?: string;
  yesText?: string;
  noText?: string;
}

export const ConfirmationPopover = ({ anchorEl, open, text, yesText, noText, close }: IProps) => {
  const { t } = useTranslation();
  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      sx={{ p: 1 }}
    >
      <Box sx={{ p: 2 }}>
        <Typography sx={{ textAlign: 'center', marginBottom: 1 }}>{text || t('base.AreYouSure')}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          <Button onClick={() => close(true)} variant="contained" size="small">
            {yesText || t('base.Yes')}
          </Button>
          <Button onClick={() => close(false)} variant="outlined" size="small">
            {noText || t('base.No')}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export default ConfirmationPopover;

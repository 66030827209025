import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { useSetLoading } from 'hooks/useLoading';
import { usePerms } from 'hooks/usePerms';
import { parseCountryCode } from 'utils';

const getFilters = gql`
  query Filters($withClusters: Boolean!, $withClients: Boolean!) {
    clients @include(if: $withClients) {
      id
      name
      displayName
    }
    clusters @include(if: $withClusters) {
      id
      name
      displayName
    }
    operators {
      id
      name
      displayName
    }
    country {
      id
    }
    currency {
      id
    }
    slots {
      id: slotId
      name: slotName
    }
  }
`;

interface IRawFilterData {
  name: string;
  displayName: string;
  id: string;
}

interface IFilterData {
  name: string;
  id: string;
}

const defaultMap = (el: IRawFilterData) => ({ id: el.id, name: el.displayName || el.name || el.id });
const defaultSort = (a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name);

export interface IDefaultFiltersData {
  clients: IFilterData[];
  clusters: IFilterData[];
  operators: IFilterData[];
  countries: IFilterData[];
  currencies: IFilterData[];
  games: IFilterData[];
}

export const useDefaultFiltersData = (loadingKey: string) => {
  const perms = usePerms();
  const { loading, data } = useQuery<{
    clients?: IRawFilterData[];
    clusters?: IRawFilterData[];
    operators: IRawFilterData[];
    country: IRawFilterData[];
    currency: IRawFilterData[];
    slots: IRawFilterData[];
  }>(getFilters, { variables: { withClusters: perms.clusters, withClients: perms.clients } });

  const [filtersData, setFiltersData] = useState<null | IDefaultFiltersData>(null);
  const { i18n } = useTranslation();
  const { startLoading, endLoading } = useSetLoading();

  useEffect(() => {
    loading ? startLoading(loadingKey) : endLoading(loadingKey);
  }, [loading]);

  useEffect(() => {
    if (data) {
      const countries = data.country
        .map(({ id }) => ({ id, name: parseCountryCode(id, i18n.language) }))
        .sort(defaultSort);
      setFiltersData((currentFilters) => (currentFilters ? { ...currentFilters, countries } : null));
    }
  }, [i18n.language]);

  useEffect(() => {
    if (data) {
      const clients = (data.clients || []).map(defaultMap).sort(defaultSort);
      const operators = data.operators.map(defaultMap).sort(defaultSort);
      const clusters = (data.clusters || []).map(defaultMap).sort(defaultSort);
      const countries = data.country
        .map(({ id }) => ({ id, name: parseCountryCode(id, i18n.language) }))
        .sort(defaultSort);
      const currencies = data.currency.map(defaultMap).sort(defaultSort);
      const games = data.slots.map(defaultMap).sort(defaultSort);

      setFiltersData({ clients, operators, clusters, countries, currencies, games });
    }
  }, [data]);

  return { filtersData, loading, rawData: data };
};

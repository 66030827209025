import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ButtonInputForm, type IButtonInputsFormAppliedFilter } from 'components';
import { useButtonInputsFormQueryParams, type IDefaultFiltersData } from 'hooks';

const inputsData = [
  { type: 'select', key: 'games', translateKey: 'playerBrowser.games' },
  { type: 'uuid', key: 'betId', translateKey: 'playerBrowser.betId' },
  { type: 'number', key: 'bet', translateKey: 'playerBrowser.betValue' },
  { type: 'number', key: 'win', translateKey: 'playerBrowser.winValue' },
  { type: 'date', key: 'betTime', translateKey: 'playerBrowser.betTime' },
  { type: 'select', key: 'entityType', translateKey: 'playerBrowser.betType' },
] as const;

const initialShowingInputs = ['games', 'betId', 'bet', 'win', 'betTime', 'entityType'];

interface IProps {
  onAccept: (filters: IButtonInputsFormAppliedFilter[]) => void;
  filtersData: IDefaultFiltersData | null;
  loading: boolean;
}

export const PlayerBrowserDetailsFilters = ({ onAccept, filtersData, loading }: IProps) => {
  const { t } = useTranslation();
  const { setQueryParams, initialState } = useButtonInputsFormQueryParams(window.location.search);
  const [showingInputs, setShowingInputs] = useState(() => {
    const showingInputs = [...initialShowingInputs];
    Object.keys(initialState).forEach((key) => {
      if (!showingInputs.includes(key)) {
        showingInputs.push(key);
      }
    });
    return showingInputs;
  });

  const handleAccept = useCallback((filters: IButtonInputsFormAppliedFilter[]) => {
    setQueryParams(filters);
    onAccept(filters);
  }, []);

  return (
    <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: 1, alignItems: 'center', pl: 3, pr: 3 }}>
      <ButtonInputForm
        inputsData={inputsData}
        showingInputs={showingInputs}
        setShowingInputs={setShowingInputs}
        initialState={initialState}
        loading={loading}
        selectOptions={{
          ...filtersData,
          entityType: [
            { name: t('playerBrowser.regularBet'), id: 'BET' },
            { name: t('playerBrowser.bonusBet'), id: 'BONUS' },
          ],
        }}
        onAccept={handleAccept}
        acceptOnInit
      />
    </Box>
  );
};

export default PlayerBrowserDetailsFilters;

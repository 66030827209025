export enum ERoutes {
  Default = '/',
  SignIn = '/signIn',
  AccountingReport = '/report/financial/accounting',
  PlayerBrowser = '/report/player/browser',
  PlayerBrowserDetails = 'report/player/browser/:id',
  GameBrowser = '/report/game/browser',
  MonitoringGames = '/monitoring/games',
  MonitoringPlayers = '/monitoring/players',
  ManagementTeamMembers = '/management/team/members',
  ManagementGamesConfiguration = '/management/games/configuration',
}

import { ToastContainer } from 'react-toastify';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { initSupertokens } from 'auth';
import { Loading } from 'components';
import { useTheme } from 'hooks';
import { Router } from 'routing';
import { client } from './apollo';
import { initTranslations } from './translation';

initTranslations();
initSupertokens();

const App = () => {
  const { theme } = useTheme();

  return (
    <SuperTokensWrapper>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <Loading />
          <Router />
          <ToastContainer />
        </ThemeProvider>
      </ApolloProvider>
    </SuperTokensWrapper>
  );
};

export default App;

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HeaderText, type IButtonInputsFormAppliedFilter, type INumberButtonInputState } from 'components';
import { useButtonInputsFormWithDataGridAndDefaultQuery, useDefaultFiltersData, usePerms } from 'hooks';
import { parseButtonInputsFormToWhere } from 'utils';
import { GET_PLAYERS, parsePlayerBrowserData, type IPlayerBrowserRawData } from './parsePlayerBrowserData';
import PlayerBrowserFilters from './PlayerBrowserFilters';
import PlayerBrowserTable from './PlayerBrowserTable';

const keysMap: Record<string, string> = {
  games: 'slotId',
  win: 'creditAmount',
  bet: 'debitAmount',
  betTime: 'createdAt',
  currencies: 'currency',
  countries: 'country',
  clients: 'clientId',
  operators: 'operatorId',
  clusters: 'clusterId',
  betId: 'entityId',
};

export const PlayerBrowser = () => {
  const { t, i18n } = useTranslation();
  const perms = usePerms();
  const { setWhereFilters, page, setPage, setSort, rawData, sort } =
    useButtonInputsFormWithDataGridAndDefaultQuery<IPlayerBrowserRawData>({
      GQL: GET_PLAYERS,
      loadingKey: 'fetchPlayerBrowserData',
      fetchVariables: {
        withClients: perms.clients,
        withClusters: perms.clusters,
      },
      keepData: true,
    });
  const { filtersData, loading } = useDefaultFiltersData('fetchPlayerBrowserFilters');

  const { data, totalCount } = useMemo(() => {
    if (rawData) {
      return parsePlayerBrowserData(rawData, t, i18n.language);
    }
    return { data: [], totalCount: -1 };
  }, [rawData, i18n.language]);

  const handleAcceptFilters = useCallback((filters: IButtonInputsFormAppliedFilter[]) => {
    const mappedFilters = filters.map((filter) => {
      if (filter.key === 'bet' || filter.key === 'win') {
        const state = filter.state as INumberButtonInputState;
        return {
          ...filter,
          state: {
            ...state,
            value: state.value ? state.value * 100 : state.value,
            value2: state.value2 ? state.value2 * 100 : state.value2,
          },
        };
      }
      return filter;
    });
    const whereFilters = parseButtonInputsFormToWhere(mappedFilters, keysMap);
    setWhereFilters(whereFilters);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 6 }}>
      <HeaderText ignoreDrawer>{t('playerBrowser.title')}</HeaderText>
      <PlayerBrowserFilters onAccept={handleAcceptFilters} filtersData={filtersData} loading={loading} />
      <PlayerBrowserTable
        data={data}
        page={page}
        setPage={setPage}
        setSort={setSort}
        totalCount={totalCount}
        sort={sort}
      />
    </Box>
  );
};

export default PlayerBrowser;

import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type Perms } from 'auth';
import { Box, ButtonInputForm, type IButtonInputsFormAppliedFilter, type IButtonInputsFormInputType } from 'components';
import { useButtonInputsFormQueryParams, usePerms, type IDefaultFiltersData } from 'hooks';

const generateInputsData = (perms: Perms) => {
  const data: { type: IButtonInputsFormInputType; key: string; translateKey: string }[] = [
    { type: 'select', key: 'games', translateKey: 'playerBrowser.games' },
    { type: 'uuid', key: 'playerId', translateKey: 'playerBrowser.playerId' },
    { type: 'uuid', key: 'playerExternalId', translateKey: 'playerBrowser.playerExternalId' },
    { type: 'uuid', key: 'playerInternalId', translateKey: 'playerBrowser.playerInternalId' },
    { type: 'uuid', key: 'betId', translateKey: 'playerBrowser.betId' },
    { type: 'number', key: 'bet', translateKey: 'playerBrowser.betValue' },
    { type: 'number', key: 'win', translateKey: 'playerBrowser.winValue' },
    { type: 'date', key: 'betTime', translateKey: 'playerBrowser.betTime' },
    { type: 'select', key: 'operators', translateKey: 'playerBrowser.operators' },
    { type: 'select', key: 'currencies', translateKey: 'playerBrowser.currencies' },
    { type: 'select', key: 'countries', translateKey: 'playerBrowser.countries' },
    { type: 'select', key: 'entityType', translateKey: 'playerBrowser.betType' },
  ];
  if (perms.clients) {
    data.push({ type: 'select', key: 'clients', translateKey: 'playerBrowser.clients' });
  }
  if (perms.clusters) {
    data.push({ type: 'select', key: 'clusters', translateKey: 'playerBrowser.clusters' });
  }
  return data;
};

const initialShowingInputs = [
  'games',
  'playerId',
  'playerExternalId',
  'playerInternalId',
  'betId',
  'bet',
  'win',
  'betTime',
];

interface IProps {
  onAccept: (filters: IButtonInputsFormAppliedFilter[]) => void;
  filtersData: IDefaultFiltersData | null;
  loading: boolean;
}

export const PlayerBrowserFilters = ({ onAccept, loading, filtersData }: IProps) => {
  const { setQueryParams, initialState } = useButtonInputsFormQueryParams(window.location.search);
  const [showingInputs, setShowingInputs] = useState(() => {
    return [...initialShowingInputs, ...Object.keys(initialState).filter((key) => !initialShowingInputs.includes(key))];
  });
  const { t } = useTranslation();
  const perms = usePerms();
  const inputsData = useMemo(() => generateInputsData(perms), [perms]);

  const handleAccept = useCallback((filters: IButtonInputsFormAppliedFilter[]) => {
    setQueryParams(filters);
    onAccept(filters);
  }, []);

  return (
    <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: 1, alignItems: 'center', pl: 3, pr: 3 }}>
      <ButtonInputForm
        inputsData={inputsData}
        showingInputs={showingInputs}
        setShowingInputs={setShowingInputs}
        initialState={initialState}
        loading={loading}
        selectOptions={{
          ...filtersData,
          entityType: [
            { name: t('playerBrowser.regularBet'), id: 'BET' },
            { name: t('playerBrowser.bonusBet'), id: 'BONUS' },
          ],
        }}
        onAccept={handleAccept}
      />
    </Box>
  );
};

export default PlayerBrowserFilters;

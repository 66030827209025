import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, type QueryResult } from '@apollo/client';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useGridApiContext, type GridSortModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { Box, Button, MenuItem, PopperMenu, Tooltip } from 'components';
import { normalizeEnabledAtom, usePerms, useReportExport } from 'hooks';
import { appliedFiltersAtom, parseAccountingFilters } from 'hooks/useAccountingData';
import { showToast } from 'utils';

const GET_CSV = gql`
  query ReportTransactionsCsv(
    $filter: JSONObject
    $orderBy: String
    $orderDirection: String
    $columnsMappers: [ReportColumnMapperInput!]!
    $normalized: Boolean!
  ) {
    reportTransactionsCsv(
      filter: $filter
      orderBy: $orderBy
      orderDirection: $orderDirection
      columnsMappers: $columnsMappers
      normalized: $normalized
    ) {
      url
    }
  }
`;

const GET_XLSX = gql`
  query ReportTransactionsXlsx(
    $filter: JSONObject
    $orderBy: String
    $orderDirection: String
    $columnsMappers: [ReportColumnMapperInput!]!
  ) {
    reportTransactionsXlsx(
      filter: $filter
      orderBy: $orderBy
      orderDirection: $orderDirection
      columnsMappers: $columnsMappers
    ) {
      url
    }
  }
`;

interface IProps {
  sort: GridSortModel;
}

export const AccountingReportExport = ({ sort }: IProps) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const appliedFilters = useAtomValue(appliedFiltersAtom);
  const normalizeEnabled = useAtomValue(normalizeEnabledAtom);
  const perms = usePerms();
  const { generatingReport, generate } = useReportExport();

  const handleClick = useCallback(
    (type: 'csv' | 'xlsx') => {
      setOpen(false);

      if (!appliedFilters) {
        showToast({ severity: 'warning', title: t('accounting.applyFiltersBeforeExport') });
        return null;
      }

      const dateDiff = dayjs(appliedFilters.dateTo).diff(appliedFilters.dateFrom, 'day');

      if (dateDiff > 366) {
        showToast({ severity: 'error', title: t('accounting.exportDateExceed') });
        return null;
      }

      const columns = apiRef.current?.getVisibleColumns();

      generate(
        type === 'xlsx' ? GET_XLSX : GET_CSV,
        {
          columnsMappers: columns.map((column) => ({ field: column.field, label: column.headerName })),
          filter: parseAccountingFilters(appliedFilters),
          orderBy: sort[0]?.field,
          orderDirection: sort[0]?.sort,
          normalized: normalizeEnabled,
        },
        (result: QueryResult) =>
          result.data?.reportTransactionsCsv?.url || result.data?.reportTransactionsXlsx?.url || '',
      );
    },
    [appliedFilters],
  );

  if (!perms.reportsAccountingExport) {
    return null;
  }

  return (
    <>
      <Tooltip title={generatingReport ? t('reportExport.onlyOneAtTime') : ''}>
        <Box>
          <Button
            ref={buttonRef}
            disabled={generatingReport}
            variant="text"
            size="small"
            onClick={() => setOpen((s) => (generatingReport ? false : !s))}
          >
            <FileDownloadIcon fontSize="small" sx={{ mr: 1 }} />
            {t('base.export')}
          </Button>
        </Box>
      </Tooltip>
      <PopperMenu anchorEl={buttonRef.current} open={open} onClose={() => setOpen(false)}>
        <MenuItem onClick={() => handleClick('csv')}>CSV</MenuItem>
        <MenuItem onClick={() => handleClick('xlsx')}>XLSX</MenuItem>
      </PopperMenu>
    </>
  );
};

export default AccountingReportExport;

import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  Box,
  GameHistoryViewer,
  HeaderText,
  SubWindow,
  Typography,
  type IButtonInputsFormAppliedFilter,
} from 'components';
import { useButtonInputsFormWithDataGridAndDefaultQuery, useDefaultFiltersData, usePerms } from 'hooks';
import { parseButtonInputsFormToWhere } from 'utils';
import {
  GET_PLAYER,
  parsePlayerBrowserDetailsData,
  type IPlayerBrowserDetailsRawData,
} from './parsePlayerBrowserDetailsData';
import { PlayerBrowserDetailsFilters } from './PlayerBrowserDetailsFilters';
import PlayerBrowserDetailsTable from './PlayerBrowserDetailsTable';

const keysMap: Record<string, string> = {
  games: 'slotId',
  win: 'creditAmount',
  bet: 'debitAmount',
  betTime: 'createdAt',
  currencies: 'currency',
  countries: 'country',
  clients: 'clientId',
  operators: 'operatorId',
  clusters: 'clusterId',
};

export const PlayerBrowserDetails = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const perms = usePerms();
  const [selectedBetId, setSelectedBetId] = useState<string | number | null>(null);
  const playerDataRef = useRef<Record<string, string | undefined> | null>(null);

  const { setWhereFilters, page, setPage, setSort, rawData, sort } =
    useButtonInputsFormWithDataGridAndDefaultQuery<IPlayerBrowserDetailsRawData>({
      GQL: GET_PLAYER,
      loadingKey: 'fetchPlayerBrowserDetailsData',
      fetchVariables: {
        withClients: perms.clients,
        withClusters: perms.clusters,
      },
    });
  const { filtersData, loading } = useDefaultFiltersData('fetchPlayerBrowserDetailsFilters');

  const handleAcceptFilters = useCallback((filters: IButtonInputsFormAppliedFilter[]) => {
    const whereFilters = parseButtonInputsFormToWhere(filters, keysMap);
    setWhereFilters({
      playerId: params.id,
      ...whereFilters,
    });
  }, []);

  const { data, totalCount } = useMemo(() => {
    if (rawData) {
      const parsedData = parsePlayerBrowserDetailsData(rawData, i18n.language);
      if (parsedData.data[0]) {
        playerDataRef.current = parsedData.data[0];
      }
      return parsedData;
    }
    return { data: [], totalCount: -1 };
  }, [rawData, i18n.language]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
      <HeaderText ignoreDrawer>{t('playerBrowser.title')}</HeaderText>
      <Box>
        {(
          [
            { key: 'playerId', translationKey: 'playerBrowser.playerId' },
            { key: 'playerExternalId', translationKey: 'playerBrowser.playerExternalId' },
            { key: 'playerInternalId', translationKey: 'playerBrowser.playerInternalId' },
            perms.clusters && { key: 'cluster', translationKey: 'playerBrowser.cluster' },
            perms.clients && { key: 'client', translationKey: 'playerBrowser.client' },
            { key: 'operator', translationKey: 'playerBrowser.operator' },
            { key: 'country', translationKey: 'playerBrowser.country' },
          ] as const
        ).map(
          (item) =>
            item && (
              <Typography key={item.key} variant="body2" component="div">
                <b>{t(item.translationKey)}</b>
                {`: ${playerDataRef.current ? playerDataRef.current[item.key] : '...'}`}
              </Typography>
            ),
        )}
      </Box>

      <PlayerBrowserDetailsFilters onAccept={handleAcceptFilters} filtersData={filtersData} loading={loading} />
      <PlayerBrowserDetailsTable
        data={data}
        page={page}
        totalCount={totalCount}
        setPage={setPage}
        setSort={setSort}
        sort={sort}
        selectedBetId={selectedBetId}
        setSelectedBetId={setSelectedBetId}
      />
      <SubWindow
        onClose={() => setSelectedBetId(null)}
        startOpened
        title={t('playerBrowser.roundDetails')}
        sx={{ visibility: selectedBetId ? 'visible' : 'hidden' }}
        startSize={{ x: 520, y: window.innerHeight - 150 }}
        startPosition={{ x: window.innerWidth - 550, y: 85 }}
        elevation={8}
      >
        <GameHistoryViewer betId={selectedBetId} elevation={8} />
      </SubWindow>
    </Box>
  );
};

export default PlayerBrowserDetails;

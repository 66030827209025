import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Button, IconButton, List, ListItem, ListItemButton, ListItemText, Popover } from 'components';
import en from './flags/en.svg';
import ja from './flags/ja.svg';
import pl from './flags/pl.svg';
import LanguageSelectorAvatar from './LanguageSelectorAvatar';

const options = [
  { label: 'English', key: 'en-US', flag: en },
  { label: 'Japan', key: 'ja-JP', flag: ja },
  { label: 'Polish', key: 'pl-PL', flag: pl },
];

interface IProps {
  variant?: 'button' | 'icon';
}

export const LanguageSelector = ({ variant }: IProps) => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const rootRef = useRef<HTMLButtonElement>(null);
  const handleChange = useCallback(
    (key: string) => {
      setOpen(false);
      i18n.changeLanguage(key);
    },
    [i18n],
  );

  const currentOption = options.find((option) => option.key === i18n.language) || options[0];

  return (
    <>
      {variant === 'icon' ? (
        <IconButton color="inherit" onClick={() => setOpen(true)} ref={rootRef}>
          <LanguageSelectorAvatar src={currentOption.flag} alt={currentOption.label} />
        </IconButton>
      ) : (
        <Button
          variant="text"
          onClick={() => setOpen(true)}
          sx={{ display: 'flex', alignItems: 'center', color: 'text.primary' }}
          ref={rootRef}
        >
          <LanguageSelectorAvatar src={currentOption.flag} alt={currentOption.label} shadow />
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Button>
      )}

      <Popover
        open={open}
        anchorEl={rootRef.current}
        onClose={() => setOpen(false)}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        slotProps={{ paper: { sx: { display: 'flex', flexDirection: 'column', gap: 1 } } }}
      >
        <List>
          {options.map((option) => (
            <ListItem key={option.key} dense sx={{ padding: 0 }}>
              <ListItemButton dense onClick={() => handleChange(option.key)}>
                <LanguageSelectorAvatar src={option.flag} alt={option.label} shadow />
                <ListItemText primary={option.label} sx={{ marginLeft: 1 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default LanguageSelector;

export interface IAccountingFilterData {
  id: string;
  name: string;
}

export interface IAccountingFilterGroup {
  name: string;
  id: string;
  values: string[];
}

export enum ENormalizePossibleCurrencies {
  EUR = 'EUR',
}

export enum ENormalizeGroups {
  NONE = 'NONE',
  COUNTRIES = 'countries',
  OPERATORS = 'clientId',
  GAMES = 'slotId',
  CURRENCIES = 'currency',
}

export enum EFilterTesters {
  ALL = 'ALL',
  TESTERS_ONLY = 'TESTERS_ONLY',
  REAL_ONLY = 'REAL_ONLY',
}

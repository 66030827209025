import { useCallback, useEffect, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import {
  GridPreferencePanelsValue,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  useGridApiContext,
  type GridDensity,
} from '@mui/x-data-grid';
import { Button } from 'components';
import { useSaveDataGridColumnsVisibility, useSaveDataGridColumnsWidth } from 'hooks';
import { getCanvasFont, getComputedStyle, measureText } from 'utils';

interface IProps extends PropsWithChildren {
  localStorageKey: string;
  additionalColumnElements?: Record<string, string>;
}

export const DataGridToolbar = ({ localStorageKey, children, additionalColumnElements }: IProps) => {
  const apiRef = useGridApiContext();
  const { t } = useTranslation();
  useSaveDataGridColumnsWidth(localStorageKey, apiRef);
  useSaveDataGridColumnsVisibility(localStorageKey, apiRef);

  const resetColumnsWidth = useCallback(() => {
    const columns = apiRef.current.getVisibleColumns();
    const rowIds = apiRef.current.getAllRowIds();
    const widths: Record<string, number> = {};
    let cell: HTMLElement | null = null;
    for (const c of columns) {
      for (const r of rowIds) {
        cell = apiRef.current.getCellElement(r, c.field);
        if (cell) {
          break;
        }
      }
      if (cell) {
        break;
      }
    }
    if (!cell) {
      return;
    }

    const paddingLeft = Number(getComputedStyle(cell, 'padding-left').replace('px', '')) || 0;
    const paddingRight = Number(getComputedStyle(cell, 'padding-right').replace('px', '')) || 0;
    const font = getCanvasFont(cell || document.body);

    columns.forEach((column) => {
      widths[column.field] = measureText(column.headerName || '', font).width;
      if (additionalColumnElements?.[column.field]) {
        widths[column.field] = Math.max(
          widths[column.field],
          measureText(additionalColumnElements[column.field], font).width,
        );
      }
      rowIds.forEach((rowId) => {
        const cellValue = apiRef.current.getCellValue(rowId, column.field);
        widths[column.field] = Math.max(widths[column.field], measureText(cellValue, font).width);
      });
      apiRef.current.setColumnWidth(column.field, widths[column.field] + paddingLeft + paddingRight + 10);
    });
  }, []);

  useEffect(() => {
    const savedDensity = localStorage.getItem('x-data-grid-column-density') || '';
    if (['compact', 'standard', 'comfortable'].includes(savedDensity)) {
      apiRef.current.setDensity(savedDensity as GridDensity);
    }

    return apiRef.current.subscribeEvent('densityChange', (density) => {
      localStorage.setItem('x-data-grid-column-density', density);
    });
  }, []);

  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
      <Button
        variant="text"
        size="small"
        onClick={() => apiRef.current.showPreferences(GridPreferencePanelsValue.columns)}
      >
        <ViewColumnIcon fontSize="small" sx={{ mr: 1 }} />
        {apiRef.current.getLocaleText('columnMenuManageColumns')}
      </Button>
      <Button variant="text" size="small" onClick={resetColumnsWidth}>
        <AspectRatioIcon fontSize="small" sx={{ mr: 1 }} />
        {t('base.resetColumnsWidth')}
      </Button>
      {children}
    </GridToolbarContainer>
  );
};

export default DataGridToolbar;

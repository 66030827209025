import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { type IAppliedFilters } from 'hooks/useAccountingData/accountingDataAtoms';
import { currencyGroups } from './currencyList';
import {
  EFilterTesters,
  ENormalizeGroups,
  ENormalizePossibleCurrencies,
  type IAccountingFilterData,
  type IAccountingFilterGroup,
} from './types';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const initialValues: Partial<IAppliedFilters> = {};
try {
  // load and validate saved data
  const json = localStorage.getItem('accountingFilters');
  const savedData = json ? JSON.parse(json) : {};

  const checkIsArrOfStrings = (arr: unknown[]) => {
    return Array.isArray(arr) && arr.reduce<boolean>((acc, item) => acc && typeof item === 'string', true);
  };
  const stringArraysKeys = ['clusters', 'clients', 'operators', 'games', 'currencies', 'countries'] as const;
  stringArraysKeys.forEach((key) => {
    if (checkIsArrOfStrings(savedData[key])) {
      initialValues[key] = savedData[key];
    }
  });

  const datesKeys = ['dateFrom', 'dateTo', 'currencyDate'] as const;
  datesKeys.forEach((key) => {
    if (dayjs(savedData[key]).isValid()) {
      initialValues[key] = savedData[key];
    }
  });

  if (savedData?.players && Object.values(EFilterTesters).includes(savedData.players)) {
    initialValues.players = savedData.players;
  }

  if (
    savedData?.normalizeCurrency &&
    Object.values(ENormalizePossibleCurrencies).includes(savedData.normalizeCurrency)
  ) {
    initialValues.normalizeCurrency = savedData.normalizeCurrency;
  }

  if (savedData?.normalizeGroupBy && Object.values(ENormalizeGroups).includes(savedData.normalizeGroupBy)) {
    initialValues.normalizeGroupBy = savedData.normalizeGroupBy;
  }

  if (typeof savedData?.normalizeEnabled === 'boolean') {
    initialValues.normalizeEnabled = savedData.normalizeEnabled;
  }
} catch (err) {
  console.error(err);
}

export const clustersAtoms = {
  selected: atom<string[]>(initialValues.clusters || []),
  groups: atomWithStorage<IAccountingFilterGroup[]>('accountingFiltersClusterGroups', []),
  data: atom<IAccountingFilterData[]>([]),
  error: atom(false),
};

export const clientsAtoms = {
  selected: atom<string[]>(initialValues.clients || []),
  groups: atomWithStorage<IAccountingFilterGroup[]>('accountingFiltersClientsGroups', []),
  data: atom<IAccountingFilterData[]>([]),
  error: atom(false),
};

export const operatorsAtoms = {
  selected: atom<string[]>(initialValues.operators || []),
  groups: atomWithStorage<IAccountingFilterGroup[]>('accountingFiltersOperatorsGroups', []),
  data: atom<IAccountingFilterData[]>([]),
  error: atom(false),
};

export const gamesAtoms = {
  selected: atom<string[]>(initialValues.games || []),
  groups: atomWithStorage<IAccountingFilterGroup[]>('accountingFiltersGamesGroups', []),
  data: atom<IAccountingFilterData[]>([]),
  error: atom(false),
};

export const currenciesAtoms = {
  selected: atom<string[]>(initialValues.currencies || []),
  groups: atomWithStorage<IAccountingFilterGroup[]>('accountingFiltersCurrenciesGroups', currencyGroups),
  data: atom<IAccountingFilterData[]>([]),
  error: atom(false),
};

export const countriesAtoms = {
  selected: atom<string[]>(initialValues.countries || []),
  groups: atomWithStorage<IAccountingFilterGroup[]>('accountingFiltersCountriesGroups', []),
  data: atom<IAccountingFilterData[]>([]),
  error: atom(false),
};

export type TFilterAtoms = typeof clustersAtoms;

export const normalizeEnabledAtom = atom<boolean>(initialValues.normalizeEnabled || false);
export const normalizeCurrencyAtom = atom<ENormalizePossibleCurrencies>(
  initialValues.normalizeCurrency || ENormalizePossibleCurrencies.EUR,
);
export const normalizeGroupByAtom = atom<ENormalizeGroups>(initialValues.normalizeGroupBy || ENormalizeGroups.NONE);
export const currencyDateAtom = atom<string>(
  initialValues.currencyDate || dayjs().subtract(1, 'day').hour(12).minute(0).second(0).format(),
);
export const dateFromAtom = atom(initialValues.dateFrom || '');
export const dateToAtom = atom(initialValues.dateTo || '');
export const timeZoneAtom = atom(dayjs.tz.guess());
export const playersAtom = atom<EFilterTesters>(initialValues.players || EFilterTesters.REAL_ONLY);

import { useEffect, type MutableRefObject } from 'react';
import { type GridApiCommunity } from '@mui/x-data-grid/internals';

const LOCAL_STORAGE_KEY_PREFIX = 'x-data-grid-columns-width--';
export const useSaveDataGridColumnsWidth = (localStorageKey: string, apiRef: MutableRefObject<GridApiCommunity>) => {
  useEffect(() => {
    const saveColumnsWidth = () => {
      const columns = apiRef.current.getAllColumns();
      const dataToSave = columns.reduce(
        (acc, column) => {
          acc[column.field] = Number(column.width);
          return acc;
        },
        {} as Record<string, number>,
      );
      localStorage.setItem(LOCAL_STORAGE_KEY_PREFIX + localStorageKey, JSON.stringify(dataToSave));
    };

    const unsubSaveColumnWidth = apiRef.current.subscribeEvent('columnWidthChange', saveColumnsWidth);

    try {
      const columnsWidthJson = localStorage.getItem(LOCAL_STORAGE_KEY_PREFIX + localStorageKey);
      if (columnsWidthJson) {
        const parsedColumnsWidth = JSON.parse(columnsWidthJson);
        const columns = apiRef.current.getAllColumns();
        columns.forEach((column) => {
          const savedWidth = parsedColumnsWidth?.[column.field];
          if (savedWidth && typeof savedWidth === 'number') {
            apiRef.current.setColumnWidth(column.field, savedWidth);
          }
        });
      } else {
        const unsub = apiRef.current.subscribeEvent('renderedRowsIntervalChange', () => {
          if (!localStorage.getItem(LOCAL_STORAGE_KEY_PREFIX + localStorageKey)) {
            apiRef.current.autosizeColumns({ includeHeaders: true, includeOutliers: true });
          }
          unsub();
        });
        return unsub;
      }
    } catch (err) {
      console.error(err);
    }

    return unsubSaveColumnWidth;
  }, []);
};

import { useCallback } from 'react';
import { atom, useAtomValue, useSetAtom } from 'jotai';

const loadingAtom = atom(false);
const loadingSet = new Set<string>();

export const useSetLoading = () => {
  const setLoading = useSetAtom(loadingAtom);
  const startLoading = useCallback((uniqueKey: string) => {
    setLoading(true);
    loadingSet.add(uniqueKey);
  }, []);
  const endLoading = useCallback((uniqueKey: string) => {
    loadingSet.delete(uniqueKey);
    if (loadingSet.size === 0) {
      setLoading(false);
    }
  }, []);

  return {
    startLoading,
    endLoading,
  };
};

export const useLoading = () => {
  const isLoading = useAtomValue(loadingAtom);
  const loadingActions = useSetLoading();

  return { loading: isLoading, ...loadingActions };
};
